.text-holder:before {
  content: "";
  /* background: #090909; */
  position: absolute;
  top: 0;
  left: 0;
  width: 574px;
  height: 160px;
  animation: appear 1.4s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}
.text-holderh3 {
  opacity: 0;
  animation: text-appear 1.2s linear forwards;
  animation-delay: 0.7s;
  text-shadow: 2px 2px 4px #000;
}

@keyframes text-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    left: 0%;
    width: 0%;
  }

  50% {
    left: 0%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 0%;
  }
}

@keyframes appear2 {
  0% {
    left: 0%;
    width: 0%;
    background: #0c5088;
  }

  50% {
    left: 0%;
    width: 100%;
    background: #0c5088;
  }

  100% {
    left: 100%;
    width: 0%;
    background: #0c5088;
  }
}
.swiper-button-next {
  position: absolute;
  top: 0;
  width: 200px;
}
.colon::before {
  content: "";
  background: url(./Assets/images/imageIcon.png) no-repeat;
  background-position: center;
  background-size: contain;
  width: 100px;
  height: 85px;
  position: absolute;
  right: -20px;
  top: -5px;
}
.prev-arrow {
  z-index: 9999;
  bottom: 0px;
  right: 0px;
  background: white;
  color: #666;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  display: flex;
  width: 90px;
  height: 55px;
}
.next-arrow {
  z-index: 999999;
  bottom: 0px;
  right: 90px;
  background: white;
  color: #666;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  display: flex;
  width: 70px;
  height: 55px;
}
@media (max-width: 694px) {
  .img-tag {
    width: 100%;
    height: auto;
  }
}

.button-2 {
  background-color: rgba(125, 125, 125, 0.447);
  border-radius: 8px;
  border-width: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-decoration: none;
}
