@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Quicksand:wght@300;400;500;600;700&family=Water+Brush&display=swap");
body {
  position: relative;
  overflow-x: hidden;
  background: #fff;
  margin: 0px !important;
  transition: all;
  font-family: "Quicksand", sans-serif;
}
.calenderBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
  padding-top: 3rem;
  background-color: #35374b;
}
.react-calendar {
  width: 50% !important;
  border: none !important;
  background-color: transparent !important;
}
.react-calendar__tile--active {
  border-radius: 60px !important;
}
.react-calendar__tile--now {
  border-radius: 60px !important;
}
.react-calendar__tile:disabled {
  background-color: transparent !important;
}
.react-calendar__navigation button:enabled:hover {
  background-color: transparent !important;
}
.react-calendar__tile:enabled:hover {
  border-radius: 60px !important;
}
.calenderBox > .react-calendar,
.calenderBox > .react-calendar *,
.calenderBox > .react-calendar *:before,
.calenderBox > .react-calendar *:after {
  box-sizing: border-box;
  color: aliceblue;
  background-color: transparent !important;
}
.swiper-button-prev {
  display: none;
}
.calenderBox > .react-calendar > .react-calendar__navigation {
  color: white !important;
}
.calenderBox > .react-calendar {
  color: white !important;
}
.priceModalCalender > .react-calendar > .react-calendar__navigation {
  color: black !important;
}
.priceModalCalender > .react-calendar {
  color: black !important;
  width: 100% !important;
}
.MuiDialog-paper {
  padding: 20px !important;
}

.button-2 {
  background-color: rgba(125, 125, 125, 0.447);
  border-radius: 8px;
  border-width: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-decoration: none;
}
